.app {
  text-align: center;
  margin-top: 50px;
}

.link {
  margin-right: 10px;
}

.back {
  margin-top: 10px;
}